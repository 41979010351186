// extracted by mini-css-extract-plugin
export var contents404 = "_404-module--contents404--3oODT";
export var blockCenter = "_404-module--blockCenter--219ri";
export var textSmall = "_404-module--textSmall--2H1Mk";
export var textLarge = "_404-module--textLarge--SZG9b";
export var buttonAndCode = "_404-module--buttonAndCode--3D9jo";
export var buttonBlock = "_404-module--buttonBlock--2zLVB";
export var button = "_404-module--button--23NYR";
export var errorCode = "_404-module--errorCode--3hHXw";
export var mobileInfo = "_404-module--mobileInfo--MEYkk";
export var desktopInfo = "_404-module--desktopInfo--3YLtS";
export var imgAndTextRow = "_404-module--imgAndTextRow--2nvVg";
export var img = "_404-module--img--2rfTO";