import React from 'react';
import { Helmet } from 'react-helmet/es/Helmet';
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Media } from 'gatsby-plugin-fresnel';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as style from './404.module.scss';
import MainLayout from '../../components/MainLayout';
import useOnNewActiveLanguage from '../../hooks/useOnNewActiveLanguage';

export const query = graphql`
  query (
    $topMenuId: String!
    $bottomMenuId: String!
    $footerId: String!
    $socialMenuId: String!
  ) {
    ph10: file(relativePath: { eq: "images/ph-10.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1260
          quality: 50
          placeholder: BLURRED
          formats: [AUTO, WEBP]
          breakpoints: [420, 640, 720, 840, 960, 1260]
          sizes: "(max-width: 768px) 420px, 640px"
        )
      }
    }
    topMenu: wpMenu(id: { eq: $topMenuId }) {
      ...topMenuFields
    }
    bottomMenu: wpMenu(id: { eq: $bottomMenuId }) {
      ...otherMenuFields
    }
    footer: wpMenu(id: { eq: $footerId }) {
      ...otherMenuFields
    }
    socialMenu: wpMenu(id: { eq: $socialMenuId }) {
      ...otherMenuFields
    }
  }
`;

const NotFoundPage = (props) => {
  const { data, pageContext } = props;
  const { topMenu, bottomMenu, footer, socialMenu } = data;

  const { t } = useTranslation();

  // const redirectToLanguage = () => {
  //   if (typeof window === 'undefined') return;
  //   navigate(
  //     pathJoinSafeSlashes(getLangSlugFromLocale(getLocaleFromI18n(i18n))),
  //     { replace: true }
  //   );
  // };
  //
  // useOnLocalStorageChange(redirectToLanguage);

  const imgData = data?.ph10?.childImageSharp?.gatsbyImageData;

  const setNewActiveLanguage = useOnNewActiveLanguage(pageContext?.locale);

  return (
    <MainLayout
      hasNoMarginBelow
      setNewActiveLanguage={setNewActiveLanguage}
      topMenu={topMenu}
      bottomMenu={bottomMenu}
      footer={footer}
      socialMenu={socialMenu}
    >
      {/* htmlAttributes={{ lang: 'en' }} */}
      <Helmet>
        <title>Oops, no such page!</title>
        <meta
          name="Description"
          content="Ridestore Magazine Gatsby-powered website.."
        />
      </Helmet>
      <div className={style.contents404}>
        <Media lessThan="desktop" className={style.mobileInfo}>
          <h3 className={style.textLarge}>{t('page404.oops')}</h3>
          <p className={style.textSmall}>{t('page404.pageMissing')}</p>
        </Media>
        <div className={style.buttonAndCode}>
          <Media lessThan="desktop">
            <div className={style.buttonBlock}>
              <button
                type="button"
                className={style.button}
                onClick={() => {
                  navigate(-1);
                }}
              >
                {t('page404.goBack')}
              </button>
            </div>
          </Media>
          <div className={style.errorCode}>404</div>
        </div>
        <Media lessThan="desktop" className="w-100">
          {(mediaClassNames) => (
            <>
              {imgData?.images && (
                <GatsbyImage
                  className={`${mediaClassNames} ${style.img}`}
                  image={imgData}
                  alt="404"
                  title="404"
                />
              )}
            </>
          )}
        </Media>
        <Media greaterThanOrEqual="desktop" className="w-100">
          <div className={style.imgAndTextRow}>
            {imgData?.images && (
              <GatsbyImage
                className={style.img}
                image={imgData}
                alt="404"
                title="404"
              />
            )}
            <div className={style.desktopInfo}>
              <div className={style.textLarge}>{t('page404.oops')}</div>
              <div className={style.textSmall}>{t('page404.pageMissing')}</div>
              <button
                type="button"
                className={style.button}
                onClick={() => {
                  navigate(-1);
                }}
              >
                {t('page404.goBack')}
              </button>
            </div>
          </div>
        </Media>
      </div>
    </MainLayout>
  );
};

export default NotFoundPage;
